import React from 'react'
import './ComingSoon.css'
//import Sidenav from '../tutorials/Sidenav/Sidenav'

const ComingSoon = () => {
  return (
    <div className="container">
      {/* <Sidenav/> */}
      <div className='soon'>
        <h1>========= Coming Soon ==========</h1>
      </div>
    </div>
  )
}

export default ComingSoon
